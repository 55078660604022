import React, { FC } from "react";

interface ButtonProps {
	disabled?: boolean;
	loading?: boolean;
	style?: object;
	className?: string;
	type?: "submit" | "reset" | "button" | undefined;
	text: string;
	onClick?: Function;
}

const Button: FC<ButtonProps> = ({
	disabled = false,
	loading = false,
	style = {},
	className,
	type = "button",
	text = "Button",
	onClick = () => {
		return;
	},
}) => {
	return (
		<button
			disabled={disabled}
			className={`${
				!disabled ? "bg-black" : "bg-gray-300 cursor-not-allowed hover:!bg-gray-300 text-black"
			} px-4 py-2 rounded text-white ${className}`}
			onClick={() => {
				onClick();
			}}
			type={type}
			style={{ ...style }}
		>
			{loading ? "Please wait..." : text}
		</button>
	);
};

export default Button;
