import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { navigate } from "gatsby";
interface Auth {
	username: string;
	password: string;
}

interface Config extends AxiosRequestConfig {
	baseURL: string;
	auth?: Auth;
}

const baseURL = process.env.GATSBY_WOO_COM_BASE_URL as string;
const username = process.env.GATSBY_WOO_COM_CK as string;
const password = process.env.GATSBY_WOO_COM_CS as string;

const cred: Config = {
	baseURL: baseURL || "", // Ensure baseURL is a string
	auth: username && password ? { username, password } : undefined,
};

const wooCommerce: AxiosInstance = axios.create(cred);

export default wooCommerce;
