import { useState, useEffect } from "react";
import { useJwt } from "react-jwt"; // Ensure correct import path

interface AuthState {
	isLoggedIn: boolean;
	userId: string | null;
	email: string | null;
}

const useJWTChecker = (): AuthState => {
	const [authState, setAuthState] = useState<AuthState>({
		isLoggedIn: false,
		userId: null,
		email: null,
	});

	// Retrieve user data from localStorage
	const [token, setToken] = useState<string | null>(null);

	useEffect(() => {
		const userString = localStorage.getItem("user");
		const localToken = userString ? JSON.parse(userString).token : null;
		setToken(localToken);
	}, []); // Empty dependency array ensures effect runs only once after initial render

	const { isExpired, decodedToken }: any = useJwt(token || ""); // Always called at top level

	useEffect(() => {
		if (token) {
			try {
				// Token is valid or expired based on useJwt result
				const userString = localStorage.getItem("user");
				const email = userString ? JSON.parse(userString).user_email : null;
				const loggedIn = !isExpired;

				// Update state based on token validity
				setAuthState({
					isLoggedIn: loggedIn,
					userId: decodedToken?.data?.user?.id || null,
					email: email,
				});
			} catch (error) {
				// console.error("Failed to parse user data from localStorage:", error);
				setAuthState({ isLoggedIn: false, userId: null, email: null });
			}
		} else {
			setAuthState({ isLoggedIn: false, userId: null, email: null });
		}
	}, [token, isExpired, decodedToken]); // Run effect when token or decodedToken changes

	return authState;
};

export default useJWTChecker;
