import React, { DetailedHTMLProps, FC, useState } from "react";
import { EyeCloseSvg, EyeOpenSvg } from "../../Svg";

interface Props
  extends DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  className?: string;
  autoComplete?: string;
}

const TextInput: FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={"flex flex-col w-full relative"}>
      {props.label && (
        <label htmlFor={props.id}>
          {`${props.label}`}
          <span className="text-primary">
            {props.required ? "*" : " (Optional)"}
          </span>
        </label>
      )}
      <input
        {...props}
        type={props.type === "password" && showPassword ? "text" : props.type}
        className={`border border-black rounded  p-2 ${props.type === "password" ? "relative" : ""} ${props.className}`}
      />
      {props.type === "password" && (
        <button
          type="button"
          onClick={togglePasswordVisibility}
          className="absolute inset-y-0 md:top-[40px] top-[30px] md:right-[11px] right-[5px]  px-3 flex items-center text-sm leading-5"
        >
          {showPassword ? <EyeCloseSvg /> : <EyeOpenSvg />}
        </button>
      )}
    </div>
  );
};

export default TextInput;
