import { Coupon } from "../../types";
import { checkCouponValidity } from "../../utils/helper";
import wooCommerce from "../../utils/woocommerce";

export function getShippingMethods() {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await wooCommerce.get("shipping/zones/0/methods");

			return resolve(response);
		} catch (error) {
			return reject(error);
		}
	});
}

export function getCountries() {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await wooCommerce.get(
				"settings/general/woocommerce_specific_allowed_countries"
			);

			return resolve(response);
		} catch (error) {
			return reject(error);
		}
	});
}

export const getCountry = (name: string) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (!name) {
				return reject();
			}
			const response = await wooCommerce.get(`data/countries/${name}`);

			return resolve(response.data);
		} catch (error) {
			return reject(error);
		}
	});
};

export function getStates(country_code: string) {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await wooCommerce.get(`data/countries/${country_code}`);
			return resolve(response);
		} catch (error) {
			return reject(error);
		}
	});
}

export const verifyCoupon = (
	code: string,
	coupons: (Coupon | undefined)[],
	userId: string | null,
	userEmail:string | null,
	showAlert?: boolean
) => {
	return new Promise<Coupon[]>(async (resolve, reject) => {
		try {
			const response = await wooCommerce.get(`coupons?code=${code}`);
			if (response.data.length > 0 || showAlert) {
				const validCoupon = checkCouponValidity(
					response.data[0] || null,
					coupons,
					userId,
					userEmail
				);

				if (validCoupon) {
					return resolve(response.data);
				} else {
					return resolve([]);
				}
			} else {
				return resolve([]);
			}
		} catch (error) {
			return reject(error);
		}
	});
};

export const fetchAllTaxes = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await wooCommerce.get("taxes");

			return resolve(response.data);
		} catch (error) {
			return reject(error);
		}
	});
};
