import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { uniqueId } from "lodash";
import { toast } from "react-toastify";
import { findMinPricedProducts, isExistsArray } from "../components/Checkout/OrderDetails";
import { updateUserCart } from "../services/woocommerceServices/Customer";
import { CheckStock, getProductByVariantId, getProductDetails } from "../services/woocommerceServices/Products";
import { CartItem, Coupon, ICouponMetaData, ICouponMetaDataDealValue, Product, ShippingMethod } from "../types";
import { navigate } from "gatsby";

interface InitialState {
	sliderStatus: boolean
	items: CartItem[];
	subTotal: string;
	total: string;
	shippingMethod: Partial<ShippingMethod>;
	shippingSubTotal: string;
	coupon: Coupon[];
	discount: string;
	couponLoading: boolean;
	refreshUI: boolean;
	SubTotalWithCoupon: string;
}

///************************** THUNK *********************************** */

interface AdjustQuantityPayload {
	action_type: "ADD" | "REMOVE" | "REPLACE";
	productId: string;
	quantity: number;
	product: CartItem; // Ensure this type is correct
}

interface CheckAndAddToCartPayload {
	item: any;
	userId: string | null;
	quantity?: number;
	toast?: boolean;
}

interface AdjustQuantityResponse extends AdjustQuantityPayload {
	apiData: any; // Replace `any` with the actual type if known
}

export const adjustQuantity: any = createAsyncThunk<
	AdjustQuantityResponse,
	AdjustQuantityPayload
>("cart/quantityAdjustment", async (payload, { rejectWithValue }) => {
	try {
		let id;
		let quantity;

		// FIND PRODUCT ID
		if (payload.product.variant) {
			id = payload.product.variant.id;
		} else {
			id = payload.product.productId;
		}
		//
		if (payload.action_type === "ADD") {
			quantity = Number(payload.product.quantity) + 1;
		} else if (payload.action_type === "REMOVE") {
			if (payload.product.quantity > 1) {
				quantity = Number(payload.product.quantity) - 1;
			}
		} else if (payload.action_type === "REPLACE") {
			quantity = Number(payload.quantity);
		}

		// Call the stock check API
		const stockStatus: any = await CheckStock(id, quantity);

		// Check if out of stock based on API response
		if (stockStatus.instock === false) {
			toast.error("This product is out of stock.");
			return rejectWithValue("Out of stock");
		}

		// Return the payload including the API data
		return { ...payload, apiData: stockStatus };
	} catch (error: any) {
		// Handle any errors
		return rejectWithValue(error.message);
	}
});

///

export const checkAndAddToCart = createAsyncThunk<
	{
		userId: any;
		item: CheckAndAddToCartPayload["item"];
		apiData: any;
	},
	CheckAndAddToCartPayload
>("cart/checkAndAddToCart", async (payload, { getState, rejectWithValue }) => {
	try {
		console.log(payload, "<<-- payload")
		const state: any = getState();
		const existingItems = state.cart.items;

		let id;
		// FIND PRODUCT ID
		if (payload.item.variant) {
			id = payload.item.variant.id;
		} else {
			id = payload.item.productId;
		}

		// PRODUCT ID EXISTS OR NOT
		const index = existingItems.findIndex((i: any) => {
			if (!i?.variant?.id) {
				return i.productId === payload.item.productId;
			} else {
				return i?.variant?.id === payload.item.variant?.id;
			}
		});

		let quantity;
		// IF EXISTS THE ADD THE QUANTITY AND AND THEN CEHCK
		if (index > -1) {
			const item = existingItems?.[index];
			quantity = item.quantity + payload.quantity;
		} else {
			// IF DONT THEN QUANTITY 1
			quantity = payload.quantity;
		}
		// Call the stock check API
		const stockStatus: any = await CheckStock(id, quantity);

		// Check if out of stock based on API response
		if (stockStatus.instock === false) {
			toast.error("This product is out of stock.");
			return rejectWithValue("Out of stock");
		}

		// Return the payload including the API data
		return { ...payload, apiData: stockStatus };
	} catch (error: any) {
		// console.error("Error in checkAndAddToCart:", error.message);
		return rejectWithValue(error.message);
	}
});

///************************************************************* */

const initialState: InitialState = {
	items:
		typeof window !== "undefined"
			? JSON.parse(localStorage.getItem("cartData") as string) || []
			: [],
	subTotal:
		typeof window !== "undefined"
			? localStorage.getItem("subTotal") ?? "0.00"
			: "0.00",

	total:
		typeof window !== "undefined"
			? localStorage.getItem("subTotal") ?? "0.00"
			: "0.00",
	shippingMethod: {},
	shippingSubTotal:
		typeof window !== "undefined"
			? localStorage.getItem("subTotal") ?? "0.00"
			: "0.00",
	coupon: typeof window !== "undefined"
		? JSON.parse(localStorage.getItem("coupons") as string) || []
		: [],
	discount: typeof window !== "undefined"
		? localStorage.getItem("discount") ?? "0.00"
		: "0.00",
	couponLoading: false,
	refreshUI: false,
	SubTotalWithCoupon: typeof window !== "undefined"
		? localStorage.getItem("subTotal") ?? "0.00"
		: "0.00",
	sliderStatus: false
};

const calculateSubTotal = (items: CartItem[]) => {
	return items
		?.reduce((sum: any, item: CartItem) => {
			let price: any = item.variant?.price ?? item.price;
			return sum + price * item.quantity;
		}, 0)
		.toFixed(2);
};

const cartSlice = createSlice({
	name: "cart",
	initialState,
	reducers: {
		slideToggle(state) {
			state.sliderStatus = !state.sliderStatus;
		},
		slideClose(state) {
			state.sliderStatus = false;
		},
		removeFromCart: (state, action) => {
			const cartItem = state.items[action.payload.productIndex];

			// Removing BOGO coupon if the product removes from cart
			if (cartItem.couponDiscount?.couponId) {
				const couponIndex = state.coupon.findIndex(coupon => coupon.id === cartItem.couponDiscount?.couponId);

				if (couponIndex > -1) {
					state.coupon.splice(couponIndex, 1);

					localStorage.setItem("coupons", JSON.stringify(state.coupon));
				}
			}

			// Removing product
			state.items?.splice(action.payload.productIndex, 1);

			// Removing its child items for upsell
			const parent = state.items.filter(item => item.productId === action.payload.productId);
			if (parent.length < 1) {
				state.items = state.items.filter(item => item.parentId !== action.payload.productId);
			}

			// navigate(state.items[action.payload.productIndex].slug)
			// Related coupon removal
			let couponIndex = state.coupon.findIndex(item => item.product_ids.includes(action.payload.productId));
			state.discount = (Number(state.discount) + Number(state.coupon[couponIndex]?.amount)).toFixed(2);

			const productIndex = state.items.findIndex(item => item.productId === action.payload.productId);
			// console.log(couponIndex, productIndex, "<<-- productIndex")
			if (couponIndex > -1 && productIndex < 0) {
				state.coupon.splice(couponIndex, 1);
			}

			const subTotal = state.items
				?.reduce((sum: any, item: CartItem) => {
					let price: any = item.variant?.price ?? item.price;
					return sum + price * item.quantity;
				}, 0)
				.toFixed(2);

			state.subTotal = subTotal;
			state.shippingSubTotal = subTotal;
			if (state.coupon?.length > 0) {
				state.coupon.map(item => {
					const amount = item.discount_type === "percent" ? (Number(state.subTotal) * (Number(item.amount) / 100)).toFixed(2) : item.amount;

					state.SubTotalWithCoupon = (subTotal - Number(amount)).toFixed(2);

					state.total = (subTotal - Number(amount)).toFixed(2);
				})
			} else {
				state.total = subTotal;
			}
			localStorage.setItem("subTotal", subTotal);
			localStorage.setItem("cartData", JSON.stringify(state.items));

			if (action.payload.userId) {
				updateUserCart(action.payload.userId, state.items);
			}
		},
		updateShippingMethod: (state, action) => {
			state.shippingMethod = action.payload;
			state.total = (
				Number(state.subTotal) + Number(action.payload.total)
			).toFixed(2);
			state.shippingSubTotal = (
				Number(state.subTotal) + Number(action.payload.total)
			).toFixed(2);

			if (state.coupon?.length > 0) {
				state.coupon.map(item => {
					const amount = item.discount_type === "percent" ? (Number(state.subTotal) * (Number(item.amount) / 100)).toFixed(2) : item.amount;

					state.total = (Number(state.total) - Number(amount)).toFixed(2);
					state.shippingSubTotal = (Number(state.shippingSubTotal) - Number(amount)).toFixed(2);
				})
			}

			localStorage.setItem("shippingMethod", JSON.stringify(action.payload));
		},
		removeShippingMethod: (state, action) => {
			// console.log(action.payload.total, "<<-- action.payload")
			state.shippingMethod = {};
			state.total = (
				Number(state.subTotal) + Number(action.payload.total)
			).toFixed(2);
			state.shippingSubTotal = (
				Number(state.subTotal) + Number(action.payload.total)
			).toFixed(2);

			if (state.coupon?.length > 0) {
				state.coupon.map(item => {
					const amount = item.discount_type === "percent" ? (Number(state.subTotal) * (Number(item.amount) / 100)).toFixed(2) : item.amount;

					state.total = (Number(state.total) - Number(amount)).toFixed(2);
					state.shippingSubTotal = (Number(state.shippingSubTotal) - Number(amount)).toFixed(2);
				})
			}

			localStorage.removeItem("shippingMethod");
		},
		applyCoupon: (state, action) => {
			let discount = 0;
			if (action.payload?.discount_type === "fixed_cart") {
				discount = parseFloat(action.payload.amount);
			} else if (action.payload?.discount_type === "percent") {
				discount =
					parseFloat(state.subTotal) *
					(parseFloat(action.payload.amount) / 100);
			} else if (action.payload?.discount_type === "fixed_product") {
				discount = state.items.reduce(
					(total: any, item: any) =>
						total +
						(action.payload.product_ids.includes(item.productId)
							? parseFloat(action.payload.amount) * item.quantity
							: 0),
					0
				);
			}

			const coupon: Coupon = action.payload;
			const isDealExist = coupon.meta_data.findIndex(
				(c) => c.key === "_acfw_bogo_deals"
			);

			if (isDealExist > -1) {
				const BOGO_deal = coupon.meta_data.find(
					(i) => i.key === "_acfw_bogo_deals"
				);

				if (BOGO_deal) {
					if (state.items.length > BOGO_deal.value.conditions.quantity) {
						let minPricedProduct: any = findMinPricedProducts(state.items, BOGO_deal.value.deals.quantity);
						minPricedProduct.map((product: Product) => {
							console.log(product, "<<-- product");
							discount += Number(product.sale_price || product.price)
						})

					} else {
						toast.error("Your current cart hasn't met the conditions set for this coupon.")
					}
				}
			}

			state.discount = discount.toString();
			localStorage.setItem("discount", discount.toFixed(2));
			if (state.coupon.findIndex((i) => i.code === action.payload.code) > -1) {
				toast.error("Coupon is being used.");
			} else {
				state.coupon = [...state.coupon, action.payload];
				state.SubTotalWithCoupon = (Number(state.SubTotalWithCoupon) - discount).toFixed(2);
				state.total = (Number(state.total) - discount).toFixed(2);
				localStorage.setItem("coupons", JSON.stringify(state.coupon))
				toast.success("Coupon applied successfully.");
			}
		},
		removeCoupon: (state, action) => {
			let index = state.coupon.findIndex((i) => i.id === action.payload.id);
			const coupons = JSON.parse(localStorage.getItem('coupons') as string);
			if (index > -1) {
				const coupon = state.coupon[index];

				if (coupon?.meta_data?.length > 0) {
					const BOGO_deal = coupon.meta_data.find(i => i.key === "_acfw_bogo_deals");

					if (BOGO_deal) {
						const { conditions_type, deals_type, conditions, deals } = BOGO_deal.value;

						// Fetch and parse cartItems from localStorage
						let cartItems: CartItem[] = JSON.parse(localStorage.getItem("cartData") as string) || [];
						const cartItemIds: (string | number)[] = state.items.map(i => i.variant.id || i.id);
						const set = new Set(cartItems);

						if (conditions_type === "any-products" && deals_type === "any-products") {
							const newItems = state.items.map(item => {
								if (item.couponDiscount !== null && item.couponDiscount !== undefined) {
									item.couponDiscount = null; // Reset couponDiscount for the current item
								} else {
									console.log(`Item ID ${item.id} does not have a couponDiscount to reset.`);
								}

								return item;
							});

							localStorage.setItem("cartData", JSON.stringify(state.items)); // Update localStorage
						} else if (conditions_type !== "any-products" && deals_type !== "any-products") {
							const dealItemIds: (string | number)[] = conditions.map((i: any) => i.product_id);
							const isItemMatch = isExistsArray(cartItemIds, dealItemIds);
							console.log(isItemMatch, "<<-- isItemMatch");

							if (isItemMatch) {
								const dealPromises = deals.map(async (deal: ICouponMetaDataDealValue) => {
									const productIndex = cartItemIds.findIndex(i => i === deal.product_id);

									if (productIndex > -1) {
										state.items[productIndex].couponDiscount = null;
									}
								});

								Promise.all(dealPromises); // Ensure all promises are resolved
							}
						}
					}
				}

				state.coupon.splice(index, 1);

				const amount = action.payload.discount_type === "percent" ? (Number(state.subTotal) * (action.payload.amount / 100)).toFixed(2) : action.payload.amount

				state.SubTotalWithCoupon = (Number(state.SubTotalWithCoupon) + Number(amount)).toFixed(2);

				state.total = (
					Number(state.total) + Number(amount)
				).toFixed(2);
				state.discount = (Number(state.discount) - action.payload.amount).toString();

				coupons.splice(index, 1);
				localStorage.setItem('coupons', JSON.stringify(coupons));
				localStorage.setItem('discount', JSON.stringify(state.discount));
			}
		},
		userCartItems: (state, action) => {
			state.items = action.payload.items

			const subTotal = calculateSubTotal(state.items);

			state.subTotal = subTotal;
			state.shippingSubTotal = subTotal;
			state.SubTotalWithCoupon = subTotal;
			state.total = subTotal;

			localStorage.setItem("cartData", JSON.stringify(state.items));
			localStorage.setItem("subTotal", subTotal);

			if (action.payload.userId) {
				updateUserCart(action.payload.userId, state.items);
			}
		},
		setCartLoading: (state, action) => {
			state.couponLoading = action.payload;
		},
		setBackToPreviousState: (state, action) => {
			// localStorage.setItem("localTrackingState", JSON.stringify(!action.payload));
			state.refreshUI = !action.payload;
		},
		quantityAdjustment: (state, action) => {
			const { action_type, productId, quantity, product } = action.payload;

			try {
				//FIND ITEM INDEX
				const index = state.items.findIndex((i: any) => {
					if (!i?.variant?.id) {
						return i.productId === productId;
					} else {
						return i?.variant?.id === product.variant?.id;
					}
				});

				//ITEM PRESENT OR NOT
				if (index > -1) {
					const item = state.items[index];
					// TYPE OF ACTIONS
					if (action_type === "ADD") {
						item.quantity += quantity;
					} else if (action_type === "REMOVE") {
						item.quantity = Math.max(1, item.quantity - quantity);
					} else if (action_type === "REPLACE") {
						item.quantity = quantity;
					}
					// SUBTOTAL CALCULATOR
					const subTotal = calculateSubTotal(state.items);

					// SET ITEMS
					state.subTotal = subTotal;
					state.shippingSubTotal = subTotal;
					state.SubTotalWithCoupon = subTotal;
					state.total = subTotal;
					localStorage.setItem("subTotal", subTotal);
					localStorage.setItem("cartData", JSON.stringify(state.items));
				}
			} catch (error) {
				// console.error("Error adjusting quantity:", error);
			}
		},
		clearCart: (state) => {
			state.items = [];
			state.subTotal = "0.00";
			state.total = "0.00";
			state.shippingMethod = {};
			state.shippingSubTotal = "0.00";
			state.SubTotalWithCoupon = "0.00";
			state.coupon = [];

			localStorage.removeItem("cartData")
		}
	},
	extraReducers: (builder) => {
		// ADJUST QUANTITY IN  CART SLIDER START
		builder.addCase(adjustQuantity.fulfilled, (state, action) => {
			const { action_type, productId, quantity, product, apiData } =
				action.payload;
			const index = state.items.findIndex((i: any) => {
				if (!i?.variant?.id) {
					return i.productId === productId;
				} else {
					return i?.variant?.id === product.variant?.id;
				}
			});

			if (index > -1) {
				const item = state.items[index];

				if (action_type === "ADD") {
					item.quantity += quantity;
				} else if (action_type === "REMOVE") {
					item.quantity = Math.max(1, item.quantity - quantity);
				} else if (action_type === "REPLACE") {
					item.quantity = quantity;
				}

				const subTotal = calculateSubTotal(state.items);

				state.subTotal = subTotal;
				state.shippingSubTotal = subTotal;
				state.total = subTotal;

				localStorage.setItem("subTotal", subTotal);
				localStorage.setItem("cartData", JSON.stringify(state.items));

				if (action.payload.userId) {
					updateUserCart(action.payload.userId, state.items);
				}

				// toast.success("Item added to cart");
			}
		});

		builder.addCase(adjustQuantity.rejected, (state, action) => {
			// console.error("Error in quantity adjustment:", action.payload);
		});
		// ADJUST QUANTITY IN  CART SLIDER END

		// ADJUST QUANTITY IN PRODUCT DETAILS START
		builder.addCase(checkAndAddToCart.fulfilled, (state, action: any) => {
			if (state.items.length === 0) {
				state.items = [action.payload.item];
			} else {
				const linkedItemsSet = new Set(action.payload?.item?.linkedProducts);

				let linkedProducts: any[] = action.payload?.item?.linkedProducts ? [...linkedItemsSet] : [];
				const isGrouped: any = linkedProducts?.length > 0;
				if (isGrouped) {
					// TODO: Need to refactor this
					linkedProducts = linkedProducts.sort((a: any, b: any) => Number(a.id) - Number(b.id));
					const stateItem = state.items.find(item =>
						JSON.stringify(item.linkedProducts) === JSON.stringify(linkedProducts)
					);
					if (stateItem) {
						if (action.payload.quantity) {
							stateItem.quantity += action.payload.quantity;
						}
						if (action.payload.item.couponDiscount && action.payload.item.couponDiscount !== null) {
							stateItem.couponDiscount = action.payload.item.couponDiscount
						}
					} else {
						state.items.push(action.payload.item);
					}

				} else {
					const index = state.items.findIndex((i: any) => {
						if (!i?.variant?.id) {
							return i.productId === action.payload.item.productId;
						} else {
							return i?.variant?.id === action.payload.item.variant?.id;
						}
					});

					if (index > -1) {
						const item = state.items?.[index];
						if (action.payload.quantity) {
							item.quantity = item.quantity + action.payload.quantity;
						}
						item.price = Number(item.price);
						if (action.payload.item.couponDiscount && action.payload.item.couponDiscount !== null) {
							item.couponDiscount = action.payload.item.couponDiscount
						}
					} else {
						console.log("data")
						state.items = [...state.items, action.payload.item];
					}
				}
			}
			let discountedAmmount = 0.00;
			const subTotal = state.items
				?.reduce((sum: any, item: CartItem) => {
					let price: any = item?.variant?.price ? Number(item?.variant?.price) : Number(item?.price);
					let totalPrice = price * item.quantity;

					if (item?.couponDiscount && item?.couponDiscount !== null) {
						const deal = item.couponDiscount;
						if (deal.discount_type === "percent") {
							discountedAmmount = totalPrice - (price * (deal.discount_value / 100) * deal.quantity);
						} else if (deal.discount_type === "override") {
							discountedAmmount = price - deal.discount_value;
						} else if (deal.discount_type === "fixed") {
							discountedAmmount = totalPrice - (deal.discount_value * deal.quantity); // Apply the fixed discount
						}
					}

					return sum + totalPrice;
				}, 0)
				.toFixed(2);

			state.subTotal = subTotal;
			state.shippingSubTotal = subTotal;
			state.total = (subTotal - discountedAmmount).toFixed(2);
			// console.log("THIS BLOCK CALLED");
			if (state.coupon?.length > 0) {
				const coupons = state.coupon.map(item => {
					const amount = item.discount_type === "acfw_bogo" ? discountedAmmount : item.discount_type === "percent" ? (Number(state.subTotal) * (Number(item.amount) / 100)).toFixed(2) : item.amount;

					item.amount = discountedAmmount.toFixed(2);

					state.SubTotalWithCoupon = (subTotal - Number(amount)).toFixed(2);

					state.total = (subTotal - Number(amount)).toFixed(2);
					return item;
				})
				localStorage.setItem("coupons", JSON.stringify(coupons));
			} else {
				state.SubTotalWithCoupon = subTotal;
				state.total = subTotal;
			}

			localStorage.setItem("subTotal", subTotal);
			localStorage.setItem("cartData", JSON.stringify(state.items));
			if (action.payload.userId) {
				updateUserCart(action.payload.userId, state.items);
			}

			if (!action.payload.toast) {
				console.log("No message");
			} else {
				toast.success("Item added to cart");
			}



		});

		builder.addCase(checkAndAddToCart.rejected, (state, action) => {
			// console.error("Failed to add product to cart:", action.payload);
		});
		// ADJUST QUANTITY IN PRODUCT DETAILS END
	},
});

export const {
	removeFromCart,
	updateShippingMethod,
	applyCoupon,
	removeCoupon,
	userCartItems,
	setCartLoading,
	quantityAdjustment,
	clearCart,
	setBackToPreviousState,
	removeShippingMethod,
	slideToggle,
	slideClose
} = cartSlice.actions;

export default cartSlice.reducer;
